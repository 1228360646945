import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { Rout } from "./TYPE";
import AuthRouts from "./AuthRouts";
// import { useSelector } from "react-redux";
// import { checkPermissions, RootState } from "../../../redux/helper";
import PERM from "../PERM";
import { deniedPermission } from "../TYPE";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../redux/reducers/userReducer";
import { accessTokenCookie } from "../Cookie";

type pagePermissions = {
  rout: Rout;
};

export default function PagePermission({
  rout: { permissions, redirect, Element },
}: pagePermissions) {
  const navigate = useNavigate();
  const location = useLocation(); // Mevcut URL'yi almak için
  // const user = useSelector((state: RootState) => state.user);
  const user = undefined;
  const user2 = useUser();

  const [permCheck, setPermCheck] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const check: deniedPermission = false;
    if (check === false) {
      setPermCheck(true);

      return;
    }
    if (check === PERM.LOGOUT_REQUIRE._ || check === PERM.LOGIN_REQUIRE._) {
      setPermCheck(false);
      return;
    }

    navigate(redirect);
  }, [navigate, permissions, redirect, user]);

  const navigationType = useNavigationType(); // Kullanıcı buraya nasıl geldi?

  const accessToken = accessTokenCookie.get();

  useEffect(() => {

    if (user2 === false && permCheck === undefined) {

      const prevRedirect = localStorage.getItem("isAuthenticated");
      const redirectPath = encodeURIComponent(
        location.pathname + location.search
      );
      let finalRedirect = decodeURIComponent(redirectPath);

      if (finalRedirect === "/auth") {
        finalRedirect = "/panel/dashboard";
      }
      localStorage.setItem("isAuthenticated", finalRedirect);
      // **Sadece dış kaynaktan (tarayıcı URL girişi, mail linki) gelenleri kaydet**
      if (navigationType === "POP") {
        // if (
        //   !finalRedirect.includes("auth")
        //   // !finalRedirect.includes("dashboard") &&
        //   // finalRedirect !== "/panel" &&
        //   // finalRedirect !== "/"
        // ) {
        //   localStorage.setItem("isAuthenticated", finalRedirect);
        // }
      } else {
      
      }
    }
  }, [user2, location]);

  if (permCheck === undefined) {
    return <React.Fragment />;
  }

  if (!permCheck) {
    // Kullanıcı login değilse, AuthRouts'a yönlendirilirken girmek istediği sayfayı sakla

    return <AuthRouts />;
  }

  return (
    <Fragment>
      <React.Suspense fallback={<Loading />}>
        <Element />
      </React.Suspense>
    </Fragment>
  );
}

export function Loading() {
  return <CircularProgress />;
}
