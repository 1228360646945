import React from "react";
import Login from "../../pages/Login";
import { useNavigate, useLocation } from "react-router-dom";

type AuthBaseProps = {
  redirectPath?: string;
};

export default function AuthBase() {
  const navigator = useNavigate();
  const location = useLocation();

  const finalRedirect = localStorage.getItem("isAuthenticated");
  const handleLogin = () => {
    // alert(finalRedirect);
    // if (!finalRedirect?.includes("dashboard")) {
    //   // Kullanıcı login olduktan sonra yönlendirme
    //   navigator(finalRedirect, { replace: true });
    // }
    return finalRedirect
    // Kullanıcıyı önceki sayfasına yönlendir
  };

  return (
    <React.Fragment>
      <Login onLogin={handleLogin} />
    </React.Fragment>
  );
}
